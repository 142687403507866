import { useQuery } from '@tanstack/vue-query'
import type { ILeaseFieldGroup } from '@register'

const getActiveFields = (data: ILeaseFieldGroup[] | undefined = []) => {
  return (
    data
      ?.filter((group) =>
        (group.sections ?? []).some(
          (section) => (section.fields ?? []).length > 0,
        ),
      )
      .map((group) => ({
        ...group,
        sections: (group.sections ?? []).filter(
          (section) => (section.fields ?? []).length > 0,
        ),
      })) ?? []
  )
}

export const useApiLeaseFieldGroups = () => {
  const query = useQuery<ILeaseFieldGroup[]>({
    queryKey: ['getApiLeaseFieldGroups'],
    queryFn: () => fetchAPI<ILeaseFieldGroup[]>('/leaseFieldGroups'),
  })

  const activeFields = computed(() => getActiveFields(query.data.value))

  const allFields = computed(() => {
    if (
      !activeFields.value ||
      !Array.isArray(activeFields.value) ||
      activeFields.value.length === 0
    ) {
      return []
    }

    return activeFields.value.flatMap((group) =>
      group.sections.flatMap(({ fields }) => fields ?? []),
    )
  })

  return {
    ...query,
    allFields,
    activeFields,
  }
}

export const useApiLeaseFieldGroupsByCompanyId = (companyId?: string) => {
  const query = useQuery<ILeaseFieldGroup[]>({
    queryKey: ['getApiLeaseFieldGroups', companyId],
    queryFn: () => {
      // LeaseFieldGroupsService.getCompaniesLeaseFieldGroups(companyId)
      return fetchAPI<ILeaseFieldGroup[]>(
        `/companies/${companyId}/leaseFieldGroups`,
      )
    },
    enabled: !!companyId,
  })

  const activeFields = computed(() => getActiveFields(query.data.value))

  const allFields = computed(() => {
    if (
      !activeFields.value ||
      !Array.isArray(activeFields.value) ||
      activeFields.value.length === 0
    ) {
      return []
    }

    return activeFields.value.flatMap((group) =>
      group.sections.flatMap(({ fields }) => fields ?? []),
    )
  })

  return {
    ...query,
    allFields,
    activeFields,
  }
}
